import { Box, List, ListItem, Text, Icon } from "@chakra-ui/react";
import { FaRegCircle, FaCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import EMenuPageBg from "./EMenuPageBg"; // Import the E2Page component

const EMenuPage = () => {
  return (
    <Box position="relative" h="100vh" w="100vw">
      {/* Background Animation */}
      <EMenuPageBg />

      {/* Overlay Sections */}
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        display="flex"
      >
        {/* Section Left */}
        <SectionLeft />

        {/* Section Right */}
        <SectionRight />
      </Box>
    </Box>
  );
};

// ANCHOR: - Section for left side of the screen.
const SectionLeft = () => {
  return (
    <Box
      display={"flex"}
      flex={1}
      backgroundColor={"#25252550"}
      alignItems="flex-start"
      justifyContent="flex-start"
      p="2rem"
    >
      <Box display={"flex"} flexDirection={"column"}>
        <Text fontSize={"6xl"}>
          {`C`}
          <Icon as={FaCircle} />
          {` L`}
          <Icon as={FaRegCircle} />
          {` RS`}
        </Text>
        <Text fontSize={"xl"}>{`3D experiments`}</Text>
      </Box>
    </Box>
  );
};

// ANCHOR: - Section for right side of the screen.
const SectionRight = () => {
  const experimentOneUUID = "5af6362b-c63e-49ed-9a8e-9bd15aa28493".toLocaleUpperCase()

  return (
    <Box
      display="flex"
      flex={1}
      backgroundColor="#25252550"
      alignItems="center"
      justifyContent="flex-end"
      p="2rem"
    >
      <Box display="flex" flexDirection="column">
        <List spacing={4}>
          <ListItem display="flex" alignItems="center">
            <Link to="/experiments/1">
              <Text fontWeight="semibold">
                {experimentOneUUID}
              </Text>
            </Link>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default EMenuPage;
