import { Box, Divider, Text } from "@chakra-ui/react";
import { Strings } from "../../Utils/Strings";
import { FOOTER_VH } from "../../Utils/Dimensions";

export const FooterBox = () => {
  const footerHeight = `${FOOTER_VH}vh`;

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      backgroundColor="branding.general.black"
      h={footerHeight} // height of the footer container
    >
      <Divider />
      <Box
        paddingTop="1rem"
        px="4rem"
      >
        <Text color="branding.general.white">
          {Strings.GENERAL_STRINGS.COPYRIGHT}
        </Text>
      </Box>
    </Box>
  );
};
