import { Box, Divider, List, ListItem, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { HEADER_VH } from '../../Utils/Dimensions'

export const HeaderBox = () => {
	const headerHeight = `${HEADER_VH}vh`
	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				h={headerHeight}
				px="4rem"
			>
				<Box>
					<Text color="branding.general.white">kenatzumu studio</Text>
				</Box>

				<List display="flex" flexDirection="row" gap={4}>
					<Link to="/experiments">
						<ListItem cursor="pointer">
							<Text color="branding.general.white">experiments</Text>
						</ListItem>
					</Link>

					<Link to="/photography">
						<ListItem cursor="pointer">
							<Text color="branding.general.white">photography</Text>
						</ListItem>
					</Link>

					<Link rel="me" to="https://mastodon.social/@kenatzumu">
						<Text color="branding.general.white">mastodon</Text>
					</Link>
				</List>
			</Box>
			<Divider />

		</>
	)
}
