import { Box, Text } from '@chakra-ui/react'
import { FOOTER_VH, HEADER_VH } from '../../Utils/Dimensions';

export const ContentBox = () => {
  const contentHeight = `${100 - HEADER_VH - FOOTER_VH}vh`;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      h={contentHeight}>
      <Text color="branding.general.white">Welcome to my webpage. I am sharing my creations and ideas. Thank you for visiting.</Text>
    </Box>
  );
};
