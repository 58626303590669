// Supports weights 300-700
import "@fontsource-variable/red-hat-text";
// theme.js
import { extendTheme } from "@chakra-ui/react";
import { DarkColors, LightColors, PersistentColors } from "./Utils/Colors";

const theme = extendTheme({
  initialColorMode: "light",
  useSystemColorMode: false,
  fonts: {
    heading: "'Red Hat Text Variable', sans-serif",
    body: "'Red Hat Text Variable', sans-serif",
  },
  colors: {
    branding: {
      light: {
        primary: PersistentColors.RadarBlipGreen,
        background: LightColors.Background,
        white: LightColors.SafeWhite,
        black: LightColors.SafeBlack,
        gray: LightColors.Gray,
      },
      dark: {
        primary: PersistentColors.RadarBlipGreen,
        background: DarkColors.Background,
        white: DarkColors.SafeWhite,
        black: DarkColors.SafeBlack,
        gray: DarkColors.Gray,
      },
      general: {
        cyan: PersistentColors.Cyan,
        magenta: PersistentColors.Magenta,
        yellow: PersistentColors.Yellow,
        white: PersistentColors.White,
        black: PersistentColors.Black,
        coral: PersistentColors.Coral,
        radarBlipGreen: PersistentColors.RadarBlipGreen,
      },
    },
  },
  styles: {
    global: {
      body: {
        color: "branding.general.black",
      },
    },
  },
});

export default theme;
