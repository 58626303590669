import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import HomeView from "../Pages/Home/HomeView";
import EMenuPage from "../Pages/Experiments/EMenuPage";
import E1Page from "../Pages/Experiments/E1Page";
import E2Page from "../Pages/Experiments/E2Page";
import PhotographyListView from "../Pages/Photography/PhotographyListView";

const MainNavigator = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeView />} />
        <Route path="/photography" element={<PhotographyListView />} />
        <Route path="/experiments" element={<EMenuPage />} />
        <Route path="/experiments/1" element={<E1Page />} />
        <Route path="/experiments/2" element={<E2Page />} />
      </Routes>
    </Router>
  );
};

export default MainNavigator;
