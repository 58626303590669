import { Box, Image, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom';

interface Data {
  title: string
  desc: string
  href: string
  src: string
  alt: string
}

const PhotographyListView = () => {
  const data: Array<Data> = [
    {
      title: "The First Born",
      desc: "",
      href: "https://500px.com/photo/1107124276/the-first-born-by-horik",
      src: "https://drscdn.500px.org/photo/1107124276/q%3D80_m%3D600/v2?sig=34b258c6218cdeacfcbd5bd43cd1d81506047f7fadfe57cff092adeb0a861748",
      alt: "The First Born by Horik on 500px.com",
    },
    {
      title: "The Second Born",
      desc: "",
      href: "https://500px.com/photo/1107126450/the-second-born-by-horik",
      src: "https://drscdn.500px.org/photo/1107126450/q%3D80_m%3D600/v2?sig=ac77ca7c6461848b6bfb3e22552539d5195f55629cc8f657e3127e6a5fb993dc",
      alt: "The Second Born by Horik on 500px.com",
    },
    {
      title: "A New Beginning",
      desc: `This is one of the first photographs I took on film. There＇s something about it that echoes the way some of my memories feel: where the colors seem darker and the light less vivid than they truly were. The memory of this moment lingers this way.`,
      href: "https://500px.com/photo/1107369824/a-new-beginning-by-horik",
      src: "https://drscdn.500px.org/photo/1107369824/q%3D80_m%3D600/v2?sig=78fe34a6078ed0bcfe2528fa41c99938187554ab6999a71b1cef3fb560719222",
      alt: "A New Beginning by Horik on 500px.com"
    },
    {
      title: "Cacti and Succulents",
      desc: "",
      href: "https://500px.com/photo/1107052539/cacti-and-succulents-by-horik",
      src: "https://drscdn.500px.org/photo/1107052539/q%3D80_m%3D600/v2?sig=5f1b566af546501d1de1f04ee05273a5138cf4c464cd4ed5f4a70a4714b2c6d1",
      alt: "Cacti and Succulents by Horik on 500px.com"
    },
    {
      title: "Peach Sunset",
      desc: "Sunset over the Dormition of the Theotokos Cathedral, Cluj-Napoca",
      href: "https://500px.com/photo/1106991628/peach-sunset-by-horik",
      src: "https://drscdn.500px.org/photo/1106991628/q%3D80_m%3D600/v2?sig=5208ab4ee8397d6aa6bf21354140b9f6e97ec6f7a6e4cf6325ac962ddc350eb7",
      alt: "Peach Sunset by Horik on 500px.com"
    }
  ]

  return (
    <Box
      px="2rem"
      h="100vh"
      flexDir="row"
      display="flex"
      alignItems="center"
    >
      {data.map((data, index) => (
        <PhotoCard key={index} photo={data} />
      ))}
    </Box>
  );
};

const PhotoCard: React.FC<{ photo: Data }> = ({ photo }) => (
  <Box marginLeft="1rem">
    <Text fontWeight="bold" color="branding.general.white">{photo.title}</Text>
    <Link to={photo.href} target="_blank">
      <Image
        src={photo.src}
        alt={photo.alt}
        aspectRatio={4 / 5}
        fit="cover"
        width="300px"
        paddingY="1rem"
      />
    </Link>
    {/* <Text maxWidth="300px">{photo.desc}</Text> */}
  </Box>

);

export default PhotographyListView;
