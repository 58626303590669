import { Box } from "@chakra-ui/react";
import { FooterBox } from "../../Views/Home/FooterBox";
import { HeaderBox } from "../../Views/Home/HeaderBox";
import { ContentBox } from "../../Views/Home/ContentBox";

const HomeView = () => {
  return (
    <Box display="flex" flexDirection="column">
      <HeaderBox />
      <ContentBox />
      <FooterBox />
    </Box>
  );
};

export default HomeView;
